import React from "react";
import "./Hero.css";
import Header from "../Header/Header";
import hero_image from "../../assets/hero_image.png";
import hero_img_back from "../../assets/hero_image_back.png";
import heart from "../../assets/heart.png";
import calories from "../../assets/calories.png";
import {motion} from "framer-motion"
import NumberCounter from "number-counter";

import { type } from "@testing-library/user-event/dist/type";

const Hero = () => {
  const transition={type:"spring",duration:3} 
  const mobile=window.innerWidth<=768? true:false;
  return (
    <div className="hero">
    <div className="blur hero-blur"></div>
      <div className="left-hero">
        <Header />
        <div className="the-best-add">
          <motion.div 
          initial={{left:mobile? "165px":"238px"}}
          whileInView={{left:"8px"}}
          transition={{...transition, type :"tween"}}></motion.div>
          <span>The best fitness Club in the Town</span>
        </div>
        <div className="hero-text">
          <div>
            <span className="stroke-text">SHAPE </span>
            <span>YOUR</span>
          </div>
          <div>
            <span>ideal Body</span>
          </div>
          <div className="span">
            In here we will help you to shape and build your ideal body and live up your life to fullest.
          </div>
        </div>
        {/*Ending of the herotext */}
        {/* Figures */}
        <div className="Figures">
          <div>
            <span><NumberCounter end={140}  start={100}  delay="4"  preFix="+"/></span>
            <span>Extra CoaChes</span>
          </div>
          <div>
            <span><NumberCounter end={987}  start={800}  delay="4"  preFix="+"/> </span>
            <span>Member Joined</span>
          </div>
          <div>
            <span><NumberCounter end={50}  start={0}  delay="4"  preFix="+"/></span>
            <span>Fitness_Programs</span>
          </div>
        </div>
        {/* Ending of the figures */}
        <div className="hero-buttons">
          <button className="btn">Get_Started</button>
          <button className="btn">Learn More</button>
        </div>
      </div>

      <div className="right-hero">
        <button className="btn">Join Now</button>
        <motion.div
        initial={{right:"-1rem"}}
        whileInView={{right:"4rem"}}
        transition={transition}

         className="heart_rate">
          <img src={heart} alt="heart_logo" />
          <span className="sp1">Heart Rate</span>
          <span className="sp2"> +116 bpm</span>
        </motion.div>
        <img src={hero_image} className="hero-img" />
        <motion.img
        initial={{right:"11rem"}}
        whileInView={{right:"20rem"}}
        transition={transition} 
         src={hero_img_back} 
         className="hero-img-back" />
        <motion.div 
        initial={{right:"38rem"}}
        whileInView={{right:"28rem"}}
        transition={transition}
        className="calories">
          <img src={calories} alt="c1_img" />
          <div>
            <span className="burn">Calories Burned</span>
            <span className="tot">220 kcal</span>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Hero;
