import React, { useState } from "react";
import "./Header.css";
import Logo from "../../assets/logo.png";
import Bars from "../../assets/bars.png";
import { Link } from "react-scroll";

const Header = () => {
  const mobile = window.innerWidth <= 768 ? true : false;
  const [menuOpen, SetmenuOpen] = useState(false);
  return (
    <div className="header" id="header">
      <img src={Logo} alt="logo-img" className="logo" />
      {menuOpen === false && mobile === true ? (
        <div
          style={{
            background: "var(--appColor)",
            padding: "0.5rem",
            borderRadius: "5px",
          }}
          onClick={() => SetmenuOpen(true)}
        >
          <img
            src={Bars}
            alt="nav-logo"
            style={{ width: "1.5rem", height: "1.5rem" }}
          />
        </div>
      ) : (
        <ul className="header-menu">
          <li >
            <Link 
            to="header" 
            span={true}
             smooth={true} 
             onClick={() => SetmenuOpen(false)}>
              Home
            </Link>
          </li>
          <li onClick={() => SetmenuOpen(false)}>
            <Link to="Programs"
             span={true}
              smooth={true}
              onClick={() => SetmenuOpen(false)}>
              Program
            </Link>
          </li>
          <li>
            <Link 
            to="join-us"
             span={true}
              smooth={true}
               onClick={() => SetmenuOpen(false)}>
              Why us?
            </Link>
          </li>
          <li>
            <Link to="plan"
            span={true}
            smooth={true}
            onClick={() => SetmenuOpen(false)}>Plans</Link>
          </li>
          <li >
            <Link 
            to="testimonials"
             span={true} 
             smooth={true} 
             onClick={() => SetmenuOpen(false)
             }>
              Testimonials
            </Link>
          </li>
        </ul>
      )}
    </div>
  );
};

export default Header;
