import React from "react";
import  { useRef } from "react";
import "./Join.css";
import emailjs from "@emailjs/browser"
const Join = () => {
  const form =useRef()
  const sendEmail = (e) => {
    e.preventDefault();
  
    emailjs
      .sendForm('service_3bbpv1i', 'template_ecq2a7g', form.current, {
        publicKey: 'xrpQ66J81GhRkIOPR',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };
  
  return (
    <div className="Join" id="join-us">
      <div className="left-j">
        <hr />
        <div>
          <span className="stroke-text">Ready To</span>
          <span>Level Up</span>
        </div>
        <div>
          <span>Your Body</span>
          <span className="stroke-text">With us?</span>
        </div>
      </div>
      <div className="right-j">
        <form  ref={form} action="" className="email-container" onSubmit={sendEmail}>
          <input
            type="email"
            name="user-email"
            required
            placeholder="Enter Your Email"
          />
          <button className=" btn j-btn">Join Now</button>
        </form>
        
      </div>
    </div>
  );
};

export default Join;
